module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W6Z68CK","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d5c438a935fa3f05a40625159ee1752"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ar","en","fr","th","tn"],"defaultLanguage":"ar","siteUrl":"https://shipper.market","redirect":false,"i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"debug":false,"fallbackLng":"ar","supportedLngs":["ar","en","fr","th","tn"],"defaultNS":"common","redirect":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":true},"pages":[{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/(.*)?"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/blog/:uid"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/reseller-commissions-and-fees"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/reseller-commissions-and-fees/:date"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/privacy-policy"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/privacy-policy/:date"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/terms-of-use"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/terms-of-use/:date"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/return-and-dispute-policy"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/return-and-dispute-policy/:date"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/supplier-commissions-and-fees"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/supplier-commissions-and-fees/:date"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/supplier-security-deposit-conditions"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/supplier-security-deposit-conditions/:date"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/supplier-terms-and-conditions"},{"matchPath":"/:country(tn|ma)/:lang(ar|en|fr|th|tn)/supplier-terms-and-conditions/:date"}]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css?family=Noto Kufi Arabic"}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"disableBgImageOnAlpha":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
