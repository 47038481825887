/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import 'bootstrap/dist/css/bootstrap.min.css'
import './src/assets/main.scss';

import React from 'react';
import CountryRedirect from './src/components/CountryRedirect';

export const wrapRootElement = ({ element }) => (
  <CountryRedirect>{element}</CountryRedirect>
);
