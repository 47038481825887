exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-dropshipping-js": () => import("./../../../src/templates/dropshipping.js" /* webpackChunkName: "component---src-templates-dropshipping-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-reseller-commissions-and-fees-js": () => import("./../../../src/templates/reseller-commissions-and-fees.js" /* webpackChunkName: "component---src-templates-reseller-commissions-and-fees-js" */),
  "component---src-templates-return-and-dispute-policy-js": () => import("./../../../src/templates/return-and-dispute-policy.js" /* webpackChunkName: "component---src-templates-return-and-dispute-policy-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-supplier-commissions-and-fees-js": () => import("./../../../src/templates/supplier-commissions-and-fees.js" /* webpackChunkName: "component---src-templates-supplier-commissions-and-fees-js" */),
  "component---src-templates-supplier-js": () => import("./../../../src/templates/supplier.js" /* webpackChunkName: "component---src-templates-supplier-js" */),
  "component---src-templates-supplier-security-deposit-conditions-js": () => import("./../../../src/templates/supplier-security-deposit-conditions.js" /* webpackChunkName: "component---src-templates-supplier-security-deposit-conditions-js" */),
  "component---src-templates-supplier-terms-and-conditions-js": () => import("./../../../src/templates/supplier-terms-and-conditions.js" /* webpackChunkName: "component---src-templates-supplier-terms-and-conditions-js" */),
  "component---src-templates-terms-of-use-js": () => import("./../../../src/templates/terms-of-use.js" /* webpackChunkName: "component---src-templates-terms-of-use-js" */)
}

