// src/components/CountryRedirect.js
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  countries,
  defaultCountry,
  getDefaultLangForCountry,
  isLangSupportedForCountry
} from '../config/countries';
import { detectCountry } from 'helpers/utils';

const CountryRedirect = ({ children }) => {
  useEffect(() => {
    const path = window.location.pathname;
    const pathSegments = path.split('/').filter(Boolean);

    // If the URL already has a valid country and language, do nothing.
    if (pathSegments.length >= 2) {
      const [country, lang] = pathSegments;
      if (countries[country] && isLangSupportedForCountry(country, lang)) {
        localStorage.setItem('country', country);
        localStorage.setItem('lang', lang);
        return;
      }
    }

    // Try to get previously stored preferences.
    const storedCountry = localStorage.getItem('country');
    const storedLang = localStorage.getItem('lang');

    if (storedCountry && countries[storedCountry]) {
      const targetLang =
        storedLang && isLangSupportedForCountry(storedCountry, storedLang)
          ? storedLang
          : getDefaultLangForCountry(storedCountry);
      navigate(`/${storedCountry}/${targetLang}/`);
      return;
    }

    // Otherwise, detect the country using an IP lookup.
    (async () => {
      const detectedCountry = await detectCountry();
      const countryCode =
        detectedCountry && countries[detectedCountry]
          ? detectedCountry
          : defaultCountry;
      const lang = getDefaultLangForCountry(countryCode);
      // Save the detected values for future visits.
      localStorage.setItem('country', countryCode);
      localStorage.setItem('lang', lang);
      navigate(`/${countryCode}/${lang}/`);
    })();
  }, []);

  // While the redirect happens, render nothing or a loader.
  return <>{children}</>;
};

export default CountryRedirect;
