// src/config/countries.js

const countries = {
  tn: {
    id: 227,
    name: 'Tunisia',
    defaultLang: 'ar',
    supportedLangs: ['ar', 'fr', 'en']
  },
  ma: {
    id: 140,
    name: 'Morocco',
    defaultLang: 'ar',
    supportedLangs: ['ar', 'fr', 'en']
  }
  // ly: {
  //   name: 'Libya',
  //   defaultLang: 'ar',
  //   supportedLangs: ['ar', 'fr', 'en']
  // },
  // dz: {
  //   name: 'Algeria',
  //   defaultLang: 'ar',
  //   supportedLangs: ['ar', 'fr', 'en']
  // }
  // Add additional countries as needed...
};

// Define a default country if detection fails
const defaultCountry = Object.keys(countries)?.[0];

// Optional helper functions:
const getDefaultLangForCountry = countryCode =>
  countries[countryCode]?.defaultLang || 'en';

const isLangSupportedForCountry = (countryCode, lang) =>
  countries[countryCode] &&
  countries[countryCode].supportedLangs.includes(lang);

module.exports = {
  countries,
  defaultCountry,
  getDefaultLangForCountry,
  isLangSupportedForCountry
};
